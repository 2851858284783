<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('Base1CCreate')"
    @edit="(id) => onEdit('Base1CEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Bases1C',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Bases1C',
      tableCaption: 'Базы 1С',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Базы 1С',
        },
      ],
      tableHeaders: [
        {
          text: 'Название клиента',
          alias: 'customerName',
          order: 'customerName',
        },
        { text: 'Имя базы (папка на диске)', alias: 'name', order: 'name' },
        { text: 'Отображаемое имя', alias: 'text', order: 'text' },
        { text: 'Дата создания', alias: 'created', order: 'created' },
        {
          text: 'Тип информационной базы',
          alias: 'baseType',
          order: 'baseType',
        },
        { text: 'Релиз', alias: 'releaseNum', order: 'releaseNum' },
        {
          text: 'Серверная база',
          alias: 'isServerBase',
          order: 'isServerBase',
        },
        { text: 'Обновление', alias: 'updateType', order: 'updateType' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
